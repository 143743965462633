.btn-toggle {
	display: inline-flex;
	align-items: left;
	padding: 0.65rem 1.5rem!important;
	font-weight: 400!important;
	font-size: 1rem!important;
	color: rgba(243, 243, 243, 0.85)!important;
	border-radius: 0px!important;
	border-right:0px!important;
	border-top:0px!important;
	border-left:0px!important;
    width: 100%;
	margin-bottom: 0px;
    text-align: left!important;
}
.btn-toggle:hover,
.btn-toggle:focus,
.btn-toggle:focus-visible,
.btn-toggle:focus-within,
.btn-toggle:target,
.btn-toggle:active,
.btn-toggle:visited {
	padding: 0.65rem 1.5rem 0.65rem 1.3rem!important;
	border-radius: 0px!important;
	font-weight: 400!important;
	border-right:0px!important;
	border-top:0px!important;
	border-left: 3px solid #419d9d!important;
	color: #adadad!important;
    background: #252e2e!important;
}

/* .btn-toggle::before {
	width: 1.25em;
	line-height: 0;
	content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
	transition: transform 0.35s ease;
	transform-origin: 0.5em 50%;
} */


.btn-toggle-nav a {
	display: inline-flex;
	padding: 0.1875rem 0.5rem;
	margin-top: 0.125rem;
	margin-left: 1.25rem;
	text-decoration: none;
	color: rgba(243, 243, 243, 0.85)!important;
}
.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus,
.btn-toggle-nav a:focus-visible,
.btn-toggle-nav a:focus-within,
.btn-toggle-nav a:target,
.btn-toggle-nav a:active,
.btn-toggle-nav a:visited {
	border:0px!important;
	color: rgba(243, 243, 243, 0.85)!important;
}
.btn-toggle-nav a::before {
	width: 1.25em;
	line-height: 0;
	/* content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e"); */
	margin-top: 7px;
	color: rgba(243, 243, 243, 0.85)!important;
}
main {
	
}

.link{
	color: black;
}
.sidenav-bg {
	height: auto;
	width: 200px;
	min-height: 650px;
	background: #171e1e;
	box-shadow: 3px 4px 10px #a3a3a3;
}
.nav-icon {
	font-size: 12px;
    margin-right: 12px;
	color: #adadad;
}
.nav-icon1 {
    font-size: 10px;
    margin-right: 8px;
    margin-top: 6px;
	color: #adadad;
}
.nav-icon2 {
    font-size: 14px;
    margin-right: 4px;
	color: #292929;
}
.nav-icon3 {
    font-size: 18px;
    margin-right: 4px;
	color: #3366cc;
}
.padtop {
	height: 55px;
	color: #ff9900;
    border-bottom: 1px solid #313a3a;
	font-size: 14px;
    padding-top: 17px;
    font-weight: 500;
    padding-left: 24px;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    border-color: transparent!important;
}

#sidebarlist{
	margin-bottom: 0rem;
}