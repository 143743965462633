.side-container {
  padding-top: 30px;
}

.side-row {
  padding: 30px 1px;
}

.react-bootstrap-table thead {
  position: sticky;
  top: 0;
  background-color: #333;
  z-index: 1021;
  color: white;
}

#Container {
  padding-top: 0px;
}

.tableFixHead {
  overflow-y: auto;
  /* make the table scrollable if height is more than 200 px  */
  height: 330px;
  /* gives an initial height of 200px to the table */
}

.tableFixHeadd {
  overflow-y: auto;
  /* make the table scrollable if height is more than 200 px  */
  height: 480px;
  /* gives an initial height of 200px to the table */
}

.tableFixHead thead th {
  position: sticky;
  /* make the table heads sticky */
  top: 0px;
  /* table head will be placed from the top of the table and sticks to it */
  font-size: 12px;
  font-weight: 300;
  background: #46749d;
}

table {
  border-collapse: collapse;
  /* make the table borders collapse to each other */
  width: 100%;
}

th,
td {
  padding: 8px 16px;
  border: 1px solid #ccc;
  font-size: 12px;
}

.main-body{
  padding-left:10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.body-mrgn{
  max-height: 90vh;
}