#logout{
   padding-right: 3%;
}
h2{
  padding-left: 25px;
    font-weight: 500!important;
    font-size: 1.2rem!important;
    color: #293e40;
}
#home{
   color: white;
}
.sidebar {
   display: flex;
   height: 100vh;
   float: left;
   overflow: 'scroll initial';
}
.header-bg  {
   background-color:#ffffff;
   box-shadow: 3px 5px 10px #ccc;
   height: 54px;
}