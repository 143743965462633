#sidebar {
  border-right: groove;
  padding: 0%;
}

#image {
  padding: 0%;
}

#container {
  margin: 0%;

}

body {
  padding: 0;
  background-color: #efefef !important;
  font-family: sans-serif;
}

.body-mrgn {
  padding: 4%;
}

.body-mrgn h2 {
  padding-left: 0px;
  color: #293e40;
  font-weight: 600 !important;
}

.body-mrgn h2::after {
  content: "";
  width: 120px;
  height: 2px;
  display: inline-block;
  background: #293e40;
  margin: 6px 10px;
}

.form-label {
  margin-top: 1.5rem;
}

.submit .btn-primary {
  color: #2e2e2e;
  font-size: 13px !important;
  border: 1px solid #b3b3b3 !important;
  padding: 5px 16px;
  border-radius: 3px;
  margin-bottom: 6px;
  margin-right: -8px;
  background: #e3e3e3;
}

.submit .btn-primary:hover {
  color: #2e2e2e;
  background: #cecece !important;
}

.form-select {
  font-size: .8rem !important;
}

.form-control {
  font-size: .8rem !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #5863696b;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4350576b;
}

.white-bg {
  padding: 20px 30px;
  background: #fff;
  border: 1px solid #ccc;
  border-top: 3px solid #46749d;
  margin-top: 3%;
  box-shadow: 4px 3px 3px #cccccc73;
}

.btn-danger {
  border-radius: 20px !important;
  padding: 0px 6px 5px 6px !important;
}

.btn-success {
  border-radius: 20px !important;
  padding: 0px 6px 5px 6px !important;
  --bs-btn-color: #198754 !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-border-color: transparent !important;
}

.footer-bg {
  background: #f1f1f1;
  font-size: 13px;
  color: #293e57;
  box-shadow: 6px -3px 9px #ccccccab;
  /* text-align: center; */
  border-top: 1px solid #ccc;
  padding: 10px 15px;
  position: fixed;
  bottom: 0;
}

.fa-style {
  background: #fff;
  box-shadow: 3px 4px 6px #ccc;
  padding: 6px;
  margin-right: 8px;
  font-size: 30px;
  color: #fe9800;
}

.fa-style-black {
  background: #fff;
  box-shadow: 3px 4px 6px #ccc;
  padding: 6px;
  margin-right: 8px;
  font-size: 30px;
  color: #000000;
  float: right;
  cursor: pointer;
}

.btn-danger {
  --bs-btn-color: #dc3545 !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-border-color: transparentf !important;
}

.box-shadow {
  box-shadow: 4px 3px 3px #cccccc73;
}

.submit .btn-login {
  --bs-btn-color: #fff;
  font-size: 18px !important;
  border: none !important;
  padding: 5px 16px;
  border-radius: 3px;
  margin-bottom: 6px;
  background: #171e1e;
}

.submit .btn-login:hover {
  background: #283030 !important;
  color: #fff;
}

.btn-info {
  color: #171e1e;
  font-size: 16px !important;
  border: 1px solid #738585 !important;
  padding: 5px 16px !important;
  background: none !important;
  border-radius: 3px !important;
  margin-bottom: 6px !important;
}

.btn-info:hover {
  background: #283030 !important;
  color: #fff !important;
}

.table>:not(caption)>*>* {
  padding: 0.5rem 0.4rem !important;
}

.fixTop {
  position: sticky;
  z-index: 999;
  top: 0;
}